import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { keys, merge } from 'lodash'

i18n.use(LanguageDetector)

const localTranslations = {
    en: {
        cart: require('./locales/en/cart.json'),
        common: require('./locales/en/common.json'),
        comparison: require('./locales/en/comparison.json'),
        filters: require('./locales/en/filters.json'),
        footer: require('./locales/en/footer.json'),
        header: require('./locales/en/header.json'),
    },
    de: {
        cart: require('./locales/de/cart.json'),
        common: require('./locales/de/common.json'),
        comparison: require('./locales/de/comparison.json'),
        filters: require('./locales/de/filters.json'),
        footer: require('./locales/de/footer.json'),
        header: require('./locales/de/header.json'),
    },
}
// eslint-disable-next-line
const externalTranslations = require('./.external/i18n.json')
const resources = merge(externalTranslations, localTranslations)

i18n.init({
    fallbackLng: 'en',
    debug: false,

    interpolation: {
        // not needed for react
        escapeValue: false,
    },
    resources,
    ns: keys(resources), // ['cart', 'common', 'comparison', 'filters', 'footer', 'header'],
    defaultNS: 'common',
    returnObjects: true,
    react: {
        useSuspense: true,
    },

    detection: {
        caches: ['cookie'],
        lookupQuerystring: 'locale', // default is lng
        lookupCookie: 'next-i18next',
        order: ['querystring', 'cookie', 'header'], // IMPORTANT
    },

    // have a common namespace used around the full app
    // ns: ['translations'],
    // defaultNS: 'translations',

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: '',
          transEmptyNodeValue: '',
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
          useSuspense: true,
        }
        */
})

i18n.languages = ['en', 'de']

export default i18n
