/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { StyledEngineProvider } from '@mui/material'
import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { useLocation } from '@reach/router'
import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import styled, { createGlobalStyle, css, ThemeProvider } from 'styled-components'
import { CENTERED_CONTENT_PATHS } from '../../../constants/layout'
import { withTrans } from '../../../i18n/withTrans'
import CookieConsent from '../CookieConsent'
import Footer from '../Footer'
import Header from '../Header'

interface IGlobalStyle {
    readonly theme: Theme
}

const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const StyledMain = styled.main<{ isNarrowed: boolean }>`
    flex-grow: 1;
    max-width: 100%;
    width: ${({ isNarrowed }) => (isNarrowed ? 'var(--center-content-width)' : '100%')};
    margin: 0 auto;
    padding: ${({ isNarrowed }) => (isNarrowed ? '0 var(--center-content-padding)' : 0)};
    box-sizing: border-box;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: block;
    }
`

const globalCss = css`
    html {
        --layout-side-padding: 48px;

        ${({ theme }) => css`
            --text-primary-color: ${theme.palette.text.primary};
            --text-secondary-color: ${theme.palette.text.secondary};
            --text-disabled-color: ${theme.palette.text.disabled};

            --info-main-color: ${theme.palette.info.main};
            --info-light-color: ${theme.palette.info.light};
            --info-dark-color: ${theme.palette.info.dark};

            --grey-50-color: ${theme.palette.grey[50]};
            --grey-100-color: ${theme.palette.grey[100]};
            --grey-200-color: ${theme.palette.grey[200]};
            --grey-300-color: ${theme.palette.grey[300]};
            --grey-400-color: ${theme.palette.grey[400]};

            --primary-main-color: ${theme.palette.primary.main};
            --primary-light-color: ${theme.palette.primary.light};
            --primary-dark-color: ${theme.palette.primary.dark};

            --center-content-width: 1464px;
            --center-content-padding: 32px;
        `}

        ${({ theme }) => theme.breakpoints.down('xl')} {
            --layout-side-padding: 32px;
        }

        ${({ theme }) => theme.breakpoints.down('lg')} {
            --layout-side-padding: 24px;
            --center-content-padding: 20px;
        }

        ${({ theme }) => theme.breakpoints.down('sm')} {
            --layout-side-padding: 16px;
        }
    }

    body {
        color: var(--text-primary-color);
        margin: 0;
        font-family: 'Open Sans', 'Roboto', Arial, Helvetica, sans-serif;
        font-display: fallback;
    }
    a {
        text-decoration: none;
    }
    a:hover,
    a:focus {
        text-decoration: none;
    }
    a:hover {
        color: ${({ theme }) => theme.palette.text.secondary};
    }
`

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
    ${globalCss}
`

const myTheme: Theme = createTheme({
    typography: {
        fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    palette: {
        primary: {
            main: '#007dff',
        },
        info: {
            main: '#e1f2f7',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1366,
            xl: 1680,
        },
    },
})

const Layout: FC = ({ children }) => {
    const { pathname } = useLocation()
    const isNarrowed = CENTERED_CONTENT_PATHS.includes(pathname)
    const [isConfigFetched, setIsConfigFetched] = useState(false)

    useEffect(() => {
        const fetchConfig = async () => {
            await axios.get(`/api/initial/config`)
            setIsConfigFetched(true)
        }
        fetchConfig()
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={myTheme}>
                <ThemeProvider theme={myTheme}>
                    <GlobalStyle theme={myTheme} />
                    <StyledPageContainer>
                        <Header />
                        <StyledMain isNarrowed={isNarrowed}>{children}</StyledMain>
                        <Footer />
                        {isConfigFetched && <CookieConsent />}
                    </StyledPageContainer>
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    )
}

export default withTrans(Layout)
