// flags were taken here: https://www.countryflags.com/en/

import { Typography, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledImgContainer = styled('div')`
    height: 30px;
    cursor: pointer;

    transition: transform 200ms ease-in-out, opacity 200ms;
    transform: none;

    &:hover {
        transform: scale(1.1);
    }

    && img {
        /* setting these properties by gatsby image leads to slight cropping the top edge of the image  */
        transform: none;
        will-change: auto;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 24px;
    }
`

const StyledItemTypography = styled(Typography)`
    margin-left: 16px;
`

const StyledFlagContainer = styled('div')`
    width: 60px;
`

const SUPPORTED_LANGUAGES = ['de', 'en']
const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0]

const LanguageMenu: FC = () => {
    const { i18n } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const anchorElRef = useRef(null)

    const [value, setValue] = useState<string>(DEFAULT_LANGUAGE)

    const handleClick = () => setIsPopoverOpen(true)
    const handleClose = () => setIsPopoverOpen(false)

    const handleChange = (newValue: string) => {
        i18n.changeLanguage(newValue)
        setValue(newValue)
    }

    useEffect(() => {
        const lang = SUPPORTED_LANGUAGES.includes(i18n.language) ? i18n.language : DEFAULT_LANGUAGE
        setValue(lang)
    }, [])

    return (
        <>
            <div ref={anchorElRef} aria-controls='lang-menu'>
                {value === 'de' && (
                    <StyledImgContainer onClick={() => handleClick()}>
                        {isMobile && (
                            <StaticImage
                                src='./assets/germany-flag-medium.png'
                                alt='DE'
                                width={40}
                                height={24}
                            />
                        )}
                        {!isMobile && (
                            <StaticImage
                                src='./assets/germany-flag-medium.png'
                                alt='DE'
                                width={50}
                                height={30}
                            />
                        )}
                    </StyledImgContainer>
                )}
                {value === 'en' && (
                    <StyledImgContainer onClick={() => handleClick()}>
                        {isMobile && (
                            <StaticImage
                                src='./assets/united-kingdom-flag-medium.png'
                                alt='EN'
                                width={48}
                                height={24}
                            />
                        )}
                        {!isMobile && (
                            <StaticImage
                                src='./assets/united-kingdom-flag-medium.png'
                                alt='EN'
                                width={60}
                                height={30}
                            />
                        )}
                    </StyledImgContainer>
                )}
            </div>
            <Menu
                id='lang-menu'
                anchorEl={anchorElRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isPopoverOpen}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateY(20px)',
                    },
                }}
            >
                <MenuItem
                    selected={value === 'en'}
                    onClick={() => {
                        handleChange('en')
                        handleClose()
                    }}
                >
                    <StaticImage
                        src='./assets/united-kingdom-flag-medium.png'
                        alt='EN'
                        width={60}
                        height={30}
                    />
                    <StyledItemTypography>English</StyledItemTypography>
                </MenuItem>
                <MenuItem
                    selected={value === 'de'}
                    onClick={() => {
                        handleChange('de')
                        handleClose()
                    }}
                >
                    <StyledFlagContainer>
                        <StaticImage
                            src='./assets/germany-flag-medium.png'
                            alt='DE'
                            width={60}
                            height={40}
                        />
                    </StyledFlagContainer>
                    <StyledItemTypography>Deutsch</StyledItemTypography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default LanguageMenu
