import { useMediaQuery, useTheme } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

const Logo: FC = () => {
    const theme = useTheme()
    const isSmMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            {!isSmMobile && (
                <StaticImage src='./assets/sauter-logo.jpg' alt='sauter-logo' width={177} />
            )}
            {isSmMobile && (
                <StaticImage src='./assets/sauter-logo.jpg' alt='sauter-logo' width={100} />
            )}
        </>
    )
}

export default Logo
