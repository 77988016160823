import { Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { CLIENT_ID_COOKIE_NAME } from '../../../constants/cookies'

const CONSENT_COOKIE_NAME = 'omCookieConsent'
const ALL_COOKIES_VALUE = 'essential+statistics'
const STATISTICS_COOKIES_VALUE = 'statistics'

const StyledContainer = styled.div`
    padding: 25px;
    z-index: 99999;
    background: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    top: auto;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #666;

    @media (min-width: 1024px) {
        width: 620px;
        left: 50%;
        top: 20vh;
        bottom: auto;
        transform: translate(-50%, 0);
        border: 1px solid #666;
    }
`

const StyledOverlay = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
`

const StyledTitleTypography = styled(Typography)`
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 19px;
`

const StyledInputs = styled.div`
    display: flex;
`

const StyledInputContainer = styled.div`
    display: flex;
    line-height: 1.1;

    &:not(:last-child) {
        margin-right: 15px;
    }
`

const StyledInput = styled.input`
    display: none;
`

const StyledEssentialInput = styled(StyledInput)``

const StyledStatisticInput = styled(StyledInput)`
    &:checked + label::before {
        box-shadow: inset 0 0 0 3px #fff;
        background-color: #8cad2d;
    }
`

const StyledLabel = styled.label`
    cursor: pointer;
    line-height: 1.1;
    font-weight: 400;
    display: flex;
    align-items: center;

    &::before {
        width: 15px;
        height: 15px;
        border-radius: 5px;
        display: block;
        content: '';
        float: left;
        margin-right: 5px;
        box-sizing: border-box;
    }
`

const StyledEssentialLabel = styled(StyledLabel)`
    &::before {
        box-shadow: inset 0 0 0 3px #fff;
        background-color: #666;
        border: 2px solid #666;
    }
`

const StyledStatisticLabel = styled(StyledLabel)`
    &:before {
        border: 2px solid #8cad2d;
    }
`

const StyledInputLabelTypography = styled(Typography)`
    line-height: 1.1;
`

const StyledWarnTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 300;
    padding: 16px 0;
`

const StyledControls = styled.div`
    margin-bottom: 10px;
`

const StyledButton = styled.button`
    width: 100%;
    display: block;
    text-align: center;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        filter: saturate(1.6) brightness(1.07);
    }
`

const StyledAcceptAllButton = styled(StyledButton)`
    background-color: #8cad2d;
    margin-bottom: 10px;
`

const StyledAcceptStatsButton = styled(StyledButton)`
    background-color: #999;
`

const StyledButtonTypography = styled(Typography)`
    font-size: 17px;
    font-weight: 300;
    text-transform: uppercase;
    color: #fff;
`

const StyledSubnav = styled.nav`
    display: flex;
    justify-content: center;
    padding-top: 8px;
`

const StyledExternalLinkTypography = styled(Typography)`
    color: royalblue;
    margin: 0 10px;
    text-decoration: none;
    font-weight: 300;

    &:hover {
        text-decoration: underline;
    }
`

const CookieConsent: FC = () => {
    const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME])
    const [clientIdCookies] = useCookies([CLIENT_ID_COOKIE_NAME])
    const [isStatisticChecked, setIsStatisticChecked] = useState(false)
    const isSSR = typeof window === 'undefined'
    const cookieValue = cookies[CONSENT_COOKIE_NAME]
    const clientId = clientIdCookies[CLIENT_ID_COOKIE_NAME]

    if (cookieValue || isSSR) {
        return null
    }

    return (
        <>
            <StyledContainer>
                <StyledTitleTypography variant='h3'>Cookie Einstellungen</StyledTitleTypography>
                <StyledInputs>
                    <StyledInputContainer>
                        <StyledEssentialInput
                            name='cookie-essential'
                            type='checkbox'
                            id='cookie-essential'
                            disabled
                            checked
                        />
                        <StyledEssentialLabel htmlFor='cookie-essential'>
                            <StyledInputLabelTypography>Essentiell</StyledInputLabelTypography>
                        </StyledEssentialLabel>
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <StyledStatisticInput
                            name='cookie-statistic'
                            type='checkbox'
                            id='cookie-statistic'
                            onChange={(e) => setIsStatisticChecked(e.target.checked)}
                        />
                        <StyledStatisticLabel htmlFor='cookie-statistic'>
                            <StyledInputLabelTypography>Statistiken</StyledInputLabelTypography>
                        </StyledStatisticLabel>
                    </StyledInputContainer>
                </StyledInputs>
                <StyledWarnTypography>
                    Wir verwenden Cookies auf unserer Webseite. Einige sind notwendig, während
                    andere uns helfen, diese Webseite und Ihre Erfahrung zu verbessern.
                </StyledWarnTypography>
                <StyledControls>
                    <StyledAcceptAllButton
                        onClick={() => {
                            setCookie(CONSENT_COOKIE_NAME, ALL_COOKIES_VALUE)
                            if (typeof wiredminds !== 'undefined') {
                                wiredminds.trackEvent(clientId)
                            }
                        }}
                    >
                        <StyledButtonTypography>Alle akzeptieren</StyledButtonTypography>
                    </StyledAcceptAllButton>
                    <StyledAcceptStatsButton
                        onClick={() => {
                            setCookie(
                                CONSENT_COOKIE_NAME,
                                isStatisticChecked ? ALL_COOKIES_VALUE : STATISTICS_COOKIES_VALUE
                            )
                            if (typeof wiredminds !== 'undefined') {
                                wiredminds.trackEvent(clientId)
                            }
                        }}
                    >
                        <StyledButtonTypography>Speichern und schließen</StyledButtonTypography>
                    </StyledAcceptStatsButton>
                </StyledControls>
                <StyledSubnav>
                    <a
                        href='https://www.sauter-feinmechanik.com/impressum.php'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <StyledExternalLinkTypography>Impressum</StyledExternalLinkTypography>
                    </a>
                    <a
                        href='https://www.sauter-feinmechanik.com/fileadmin/templates/datenschutz.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <StyledExternalLinkTypography>
                            Datenschutzerklärung
                        </StyledExternalLinkTypography>
                    </a>
                </StyledSubnav>
            </StyledContainer>
            <StyledOverlay />
        </>
    )
}

export default CookieConsent
