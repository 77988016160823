import { ClickAwayListener, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Close, Menu } from '@mui/icons-material'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ScrollLock } from '../ScrollLock'
import HeaderNav from './HeaderNav'
import LanguageMenu from './LanguageMenu'
import Logo from './Logo'

const StyledHeader = styled.header``

const CenterHeader = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--layout-side-padding);
    box-sizing: border-box;
`

const StyledHeaderTop = styled.div`
    width: 100%;
    height: 92px;
    box-sizing: border-box;
    display: flex;

    ${({ theme }) => theme.breakpoints.down('md')} {
        height: 50px;
    }
`

const StyledHeaderInner = styled(CenterHeader)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
`

const StyledLeftSubmenu = styled.nav`
    display: flex;
    align-items: center;
`

const StyledDesktopRightSubmenu = styled.div`
    display: flex;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: none;
    }
`

const StyledMobileRightSubmenu = styled.div`
    display: none;
    color: white;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: flex;
    }
`

const StyledMobileMenu = styled(Menu)`
    margin-left: 40px;
    fill: ${({ theme }) => theme.palette.text.primary};
    cursor: pointer;
    width: 40px;
    height: 40px;

    a:hover {
        fill: ${({ theme }) => theme.palette.text.secondary};
    }
`

const StyledHomeLink = styled.a`
    margin-top: 85px;
    z-index: 1;
    transform: translateY(5px);
    transition: transform 0.3s ease-in-out;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-top: 50px;
    }

    &:hover {
        transform: translateY(10px);
    }
`

const StyledGatsbyLink = styled(GatsbyLink)`
    color: ${({ theme }) => theme.palette.text.primary};

    &:hover {
        color: ${({ theme }) => theme.palette.text.secondary};
    }
`

const StyledMobilePopup = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    background: #202020;
    z-index: 100;
`

const StyledCloseContainer = styled.div`
    position: fixed;
    top: 8px;
    right: 31px;
    color: white;
    cursor: pointer;

    svg {
        width: 40px;
        height: 40px;
    }
`

const StyledHeaderNavContainer = styled.div`
    position: fixed;
    top: 84px;
    right: 43px;
`

const StyledTitleTypography = styled(Typography)`
    font-size: 36px;
    font-weight: 300;
    margin-left: 20px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 28px;
    }
`

const StyledStaticImageContainer = styled.div`
    height: 292px;
    width: 100%;
    ${({ theme }) => theme.breakpoints.down('md')} {
        height: 170px;
    }
`

const Header: FC = () => {
    const [isMobilePopupHidden, setIsMobilePopupHidden] = useState(true)
    const theme = useTheme()
    const isSmMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { t } = useTranslation()

    return (
        <StyledHeader>
            {!isMobilePopupHidden && (
                <ClickAwayListener
                    onClickAway={() => {
                        setIsMobilePopupHidden(true)
                    }}
                >
                    <StyledMobilePopup>
                        <ScrollLock />
                        <StyledHeaderNavContainer>
                            <HeaderNav />
                        </StyledHeaderNavContainer>
                        <StaticImage
                            src='./assets/text-logo.png'
                            alt='sauter logo'
                            style={{
                                position: 'fixed',
                                top: '8px',
                                left: '31px',
                            }}
                        />
                        <StyledCloseContainer>
                            <Close
                                onClick={() => {
                                    setIsMobilePopupHidden(true)
                                }}
                            />
                        </StyledCloseContainer>
                    </StyledMobilePopup>
                </ClickAwayListener>
            )}
            <StyledHeaderTop>
                <StyledHeaderInner>
                    <StyledLeftSubmenu>
                        <StyledHomeLink href='https://www.sauter-feinmechanik.com'>
                            <Logo />
                        </StyledHomeLink>
                        <StyledGatsbyLink to='/'>
                            <StyledTitleTypography>
                                {!isSmMobile && t('header:online-catalog')}
                            </StyledTitleTypography>
                        </StyledGatsbyLink>{' '}
                    </StyledLeftSubmenu>
                    <StyledMobileRightSubmenu>
                        <LanguageMenu />
                        <StyledMobileMenu onClick={() => setIsMobilePopupHidden(false)} />
                    </StyledMobileRightSubmenu>
                    <StyledDesktopRightSubmenu>
                        <HeaderNav />
                        <LanguageMenu />
                    </StyledDesktopRightSubmenu>
                </StyledHeaderInner>
            </StyledHeaderTop>
            <StyledStaticImageContainer>
                {!isSmMobile && (
                    <StaticImage
                        src='./assets/header-bg.jpg'
                        alt='sauter logo'
                        objectFit='cover'
                        style={{ height: 292 }}
                    />
                )}
                {isSmMobile && (
                    <StaticImage
                        src='./assets/header-bg.jpg'
                        alt='sauter logo'
                        objectFit='cover'
                        style={{ height: 170 }}
                    />
                )}
            </StyledStaticImageContainer>
        </StyledHeader>
    )
}
export default React.memo(Header)
