import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IFooterLink {
    readonly href: string
    readonly title: string
    readonly needAnotherTab?: boolean
}

const StyledFooter = styled.footer`
    display: flex;
    justify-content: center;
    padding: 20px;
    background: black;
    max-width: 100vw;
    overflow: hidden;
`

const StyledLinks = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
`

const StyledLinkContainer = styled.li`
    padding: 0 16px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`

const StyledLink = styled.a`
    color: white;

    &:hover {
        color: lightgrey;
    }
`

const Footer: FC = () => {
    const { t } = useTranslation()

    const links: IFooterLink[] = [
        {
            href: 'https://www.sauter-feinmechanik.com/impressum.php',
            title: t('footer:imprint'),
        },
        {
            href: 'https://www.sauter-feinmechanik.com/fileadmin/templates/datenschutz.pdf',
            title: t('footer:data-protection'),
            needAnotherTab: true,
        },
        {
            href: 'https://www.sauter-feinmechanik.com/lieferbedingungen.php',
            title: t('footer:terms-of-delivery'),
        },
        {
            href: 'https://www.sauter-feinmechanik.com/impressum.php',
            title: t('footer:purchase-conditions'),
        },
    ]

    return (
        <StyledFooter>
            <StyledLinks>
                {links.map(({ href, title, needAnotherTab }) => (
                    <StyledLinkContainer key={title}>
                        <StyledLink
                            rel={needAnotherTab ? 'noopener noreferrer' : undefined}
                            href={href}
                            target={needAnotherTab ? '_blank' : undefined}
                        >
                            <Typography variant='caption'>{title}</Typography>
                        </StyledLink>
                    </StyledLinkContainer>
                ))}
            </StyledLinks>
        </StyledFooter>
    )
}

export default Footer
