/* eslint-disable react/display-name */
import React, { Component } from 'react'
import i18next from './config'
import { I18nextProvider, withTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function withTrans(WrappedComponent: any): any {
    WrappedComponent = withTranslation()(WrappedComponent)

    return class extends Component {
        render() {
            return (
                <I18nextProvider i18n={i18next}>
                    <WrappedComponent {...this.props} language={i18next.language} />
                </I18nextProvider>
            )
        }
    }
}
